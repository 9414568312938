<template>
   
    <div class="contact-details-main">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="container-fluid">
            <Header v-if="showHeader" />
            <div class="main-body-brands-wrapper">
                <div class="container-fluid">
                    <div class="brand-head">
                        <p>Brands</p>
                    </div>
                    <div class="brand-main" >
                            <div class="brand-1 " v-for="(item,index) in items" :key="index">
                                <a href="#">
                                    <img  :src="item.logo">
                                </a>
                                <p @click="get_brand_details(item)">{{ item.name }}<br><span class="name-desc">{{ item.abbreviation }}</span></p>
                            </div>
                            <span v-if="error" class="error_alert">{{error_message}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../layouts/Header.vue';
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'

    export default {
  components: {Header  },
        name: 'BrandsListing',
        data () {
            return {
                error:false,
                error_message:'',
                items:[],
                showHeader: true,
                loading:false
            }
        },
        methods: {
            async get_brand_details(brand){
                await this.$store.commit("setBrand",brand.id);
                await this.$store.commit("setBrandName",brand.name);
                await this.$router.push('/brands-default')
            }
        },
        beforeCreate() {
        },
        async created() {
            try{
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                const response =  await axios.get(`${baseUrl}/api/brands`,{headers})
                this.loading= false;
                this.items = response.data.results;
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                var i;
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }




        },
        beforeMount() {
        },
        mounted() {
        },
        beforeUpdate() {
        },
        updated() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed:{
        }


       
    }

</script>


<style>

</style>